<template>
  <header class="page-home-header p-top-5">
    <!-- Title -->
    <div
      class="row-1 flex --column --align-right"
      v-if="props.header.homeHeaderTitle"
    >
      <h1 data-animate="header-title" class="text-xl page-home-header__title">
        {{ props.header.homeHeaderTitle }}
      </h1>
    </div>
    <!-- Video / Picture -->
    <template
      v-if="
        props.header.homeHeaderImage?.[0] || props.header.homeHeaderVideoUrl
      "
    >
      <!-- <div
        class="m-top-4 ratio-full-screen page-home-header__image"
        v-anim-parallax-element="{
          target: props.header.homeHeaderVideoUrl ? 'video' : 'picture',
        }"
        :data-hoverable="$t('cursor.scroll')"
        data-animate="header-image"
      > -->
      <div
        class="m-top-5 ratio-full-screen page-home-header__image"
        :class="{
          '--is-video': props.header.homeHeaderVideoUrl,
        }"
        data-animate="header-image"
      >
        <WidgetVideo
          v-if="props.header.homeHeaderVideoUrl"
          :videoUrl="props.header.homeHeaderVideoUrl"
          :autoplay="true"
        />
        <WidgetPicture
          v-else-if="props.header.homeHeaderImage?.[0]"
          :image="{
            landscape: props.header.landscape?.[0],
            portrait: props.header.mobile?.[0] || props.header.portrait?.[0],
          }"
          :alt="props.header.homeHeaderImage[0].title"
        />
      </div>
    </template>
  </header>
</template>

<script>
export default {
  name: 'PageHomeHeader',
}
</script>

<script setup>
const props = defineProps({
  header: Object,
})
</script>

<style lang="scss">
@import './style.scss';
</style>
